/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";
import { ThankYou as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query {
    craft {
      entry(section: "thankYou") {
        ... on Craft_thankYou_thankYou_Entry {
          uid
          heroHeading
          fullWidthText
        }
      }
    }
  }
`;

const previewQuery = `
query {
  craft {
    entry(section: "thankYou") {
      ... on Craft_thankYou_thankYou_Entry {
        uid
        heroHeading
        fullWidthText
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const { heroHeading, fullWidthText } = craft.entry;
  return {
    heading: heroHeading,
    copy: fullWidthText,
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
